// Here you can add other styles

.hidden {
  display: none;
  visibility: hidden;
}

.borderPreview-top {
  border-top: thick solid;
  border-top-width: 25px;
}

.borderPreview-bottom {
  border-bottom: thick solid;
  border-bottom-width: 25px;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.mouse-pointer {
  cursor: pointer;
}


.pending-border {
  border-color: $info-base;
  box-shadow: 0 0 0 0.25rem rgb(51 153 255 / 25%);
}

.table-dark {
  --cui-table-bg: #F5F5F5;
}

//--cui-body-bg: #FFF
